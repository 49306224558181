@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .connect-tv-pin {
    @apply w-[50px] h-[50px] max-sm:w-[40px] max-md:h-[40px] bg-red-800 text-white rounded-full flex items-center justify-center mx-2 max-sm:mx-1;
  }
}

.bubble {
  width: 50px;
  height: 50px;
  background-color: #8b0000;
  border-radius: 50%;
  margin: 0 10px;
  animation: knightRider 1.5s ease-in-out infinite;
}

@media (max-width: 768px) {
  /* Tailwind's max-md breakpoint */
  .bubble {
    width: 40px;
    height: 40px;
    margin: 0 5px;
  }
}

.bubble:nth-child(1) {
  animation-delay: 0s;
}
.bubble:nth-child(2) {
  animation-delay: 0.2s;
}
.bubble:nth-child(3) {
  animation-delay: 0.4s;
}
.bubble:nth-child(4) {
  animation-delay: 0.6s;
}
.bubble:nth-child(5) {
  animation-delay: 0.8s;
}
.bubble:nth-child(6) {
  animation-delay: 1s;
}

@keyframes knightRider {
  0%,
  100% {
    opacity: 0.3;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}
