@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans&display=swap");

:root {
  --small-text: #8a9ba2;
  --black: #000;
  --red: #f30543;
  --green: rgba(5, 243, 73, 0.971);
  --background: #131313;
  --searchbar: rgb(58, 58, 58);
  --grey: #313131;
  --clr-white: #fff;
  --clr-purple: #4b145b;
  --clr-grey-special: #6b6b6b;
  --clr-black: #222;
  --facebook: #4267b2;
  --apple: #38415f;
  --red-circle-1: rgba(150, 16, 56, 0.1);
  --red-circle-2: rgba(151, 47, 47, 0.15);
  --red-circle-3: rgba(151, 47, 47, 0.2);
  /* used as bg in favorite and watchlist button in card*/
  --card-small-button: rgba(0, 0, 0, 0.575);
  --card-booster-button: #ffee00;
  --FAFAFA: #fafafa;
  --Color7B9DB4: #7b9db4;
  --Color1E1E1E: #131313;
  --skeletonColor: #1a1919;
  --chat-border: #3c3c3c;
  --open-chat-header: #27292d;
  --chat-bubble-blue: #0b3144;
}
* {
  /* font-family: "Plus Jakarta Sans", sans-serif !important; */
  font-family: "Alexandria", sans-serif !important;
  letter-spacing: 0.02em !important;
}
body {
  margin: 0;
  background-color: var(--background);
}

.verify-blue-tik {
  color: blue;
  height: 30px;
}

@media screen and (max-width: 480px) {
  .verify-blue-tik {
    color: blue;
    height: 18px;
    margin-top: 5px;
  }
}

.navbar {
  background-color: var(--background);
}

.navbar-image img {
  width: 110px;
  height: 21px;
}
.home-card-carousel img {
  width: 90px;
  height: 20px;
}

.creator-descrip {
  font-weight: 600 !important;
}

.color_1E1E1E {
  background-color: var(--Color1E1E1E);
}

.stream-icon-hero {
  color: red;
  font-size: 20px;
  font-weight: bolder;
}
.searchbar {
  background-color: var(--searchbar);
}
.navbar-search input {
  background-color: var(--searchbar);
}
.background-gray {
  background-color: var(--grey);
}
.button-red {
  background-color: var(--red);
}
.disabled-button {
  background-color: var(--grey);
}
.button-green {
  background-color: var(--green);
}
.button-gray {
  background-color: var(--grey);
}
.button-white {
  background-color: var(--clr-white);
}
.small-text {
  color: var(--small-text);
}
.red-text {
  color: var(--red);
}
.black-text {
  color: var(--clr-black);
}
.gray-text {
  color: var(--small-text);
}
.white-text {
  color: var(--clr-white);
}
.fafafa {
  color: var(--FAFAFA);
}
.Color7B9DB4 {
  color: var(--Color7B9DB4);
}
.header_img {
  height: 30px !important;
}
.card-img-head {
  height: 13px !important;
  display: inline !important;
  margin-top: -3px !important;
}
.landing-header-img {
  height: 80px;
  display: inline !important;
}
/* overflow image */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}
.liveButton {
  background-color: var(--green);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/* album page */
@layer components {
  .album-page-image {
    @apply w-56 h-56 mt-10 bg-cover shadow-lg;
  }
  .login-page-form-container {
    @apply relative overflow-scroll sm:w-[500px] w-[80%] left-28 top-10 text-center max-sm:left-10 max-sm:top-[10%];
  }
  .video-player-side-panel {
    @apply -mt-[88px] overflow-hidden z-10  absolute  duration-500 overflow-y-hidden text-center shadow w-[60px] p-2 !h-max;
  }

  .app-page-form-container {
    @apply relative sm:w-[500px] w-[80%] left-10 top-10 text-center max-sm:left-0 max-sm:pl-10 max-sm:w-full max-sm:top-[10%];
  }
  .update-password {
    @apply relative sm:w-[500px] w-[80%] left-28 top-5 text-center max-sm:left-10 max-sm:top-[10%];
  }
}

/* Music 3 dot Pop  */
@layer components {
  .music-playlist-popup-li {
    @apply mb-2 cursor-pointer hover:text-[#828282] ease-in-out duration-100 list-none;
  }
}

.custom-submenu.ant-menu-submenu-popup .ant-menu-item {
  color: white;
}

/*
=============== 
Questions
===============
*/

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.q-container {
  width: 80%;
  margin: 5rem auto;
  color: var(--clr-white);
}
.info {
  background: var(--searchbar);
}

.rounded-full-btn {
  border-radius: 8.5rem;
}

.question {
  padding: 1rem 1.5rem;
  border-bottom: 2px solid var(--clr-grey-special);

  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
}

.question h4 {
  text-transform: none;
  line-height: 1.5;
}

.question p {
  color: var(--clr-grey-3);
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.question header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question header h4 {
  margin-bottom: 0;
}

.btn {
  background: transparent;
  border-color: transparent;
  width: 2rem;
  height: 2rem;
  background: var(--clr-grey-special);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--clr-white);
  cursor: pointer;
  margin-left: 1rem;
  align-self: center;
  min-width: 2rem;
}

.question-title {
  cursor: pointer;
}

.hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../src/Assets/Images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.livetv-heroimage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)),
    url("../Assets/Images/LiveTvBackDrop.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  overflow: hidden;
}
.music-heroimage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)),
    url("../Assets/Images/LiveTvBackDrop.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  overflow: hidden;
}

.ppv-heroimage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)),
    url("../Assets/Images/ppv-landing-header.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
/* Grid card */

.gridCard-hover:hover {
  background-color: var(--red);
  color: var(--clr-white);
}

.gridCard-hover:hover h2 {
  color: var(--clr-white);
}
.gridCard-hover:hover p {
  color: var(--clr-white);
}
.gridCard-hover:hover h2 span {
  color: var(--clr-white);
}

.img-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* video page sidebar */
.feature-side-nav:hover,
.feature-side-nav:hover p {
  color: var(--red);
}

/* backdrop */
.backdrop-bottom {
  height: 40%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.973) 17%,
    rgba(0, 0, 0, 0) 100%
  );
}

/* backdrop */
.full-backdrop-bottom {
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, #131313 20%, rgba(0, 0, 0, 0.773) 100%);
}

/* login */
.login_modal {
  z-index: 100;
  height: 100%;
  overflow: hidden;
  background-color: #000000bb;
}

.facebook-button {
  background-color: var(--facebook);
}
.apple-button {
  background-color: var(--apple);
}

.red-circle-1 {
  background-color: var(--red-circle-1);
}
.red-circle-2 {
  background-color: var(--red-circle-2);
}
.red-circle-3 {
  background-color: var(--red-circle-3);
}
.loginpage-back {
  height: 100%;
  overflow-y: scroll;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    70deg,
    rgb(19, 19, 19) 35%,
    rgba(0, 0, 0, 0) 100%
  );
}

.videohero-back {
  height: 100%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    35deg,
    rgb(19, 19, 19) 30%,
    rgba(0, 0, 0, 0) 100%
  );
}
.payperviewhero-back {
  background: rgba(0, 0, 0, 0);
  background: linear-gradient(
    to right,
    rgba(19, 19, 19, 0.897) 30%,
    rgba(0, 0, 0, 0.829) 60%
  );
  overflow: scroll;
  backdrop-filter: blur(5px);
}

.gradient-card-back {
  width: 100%;
  height: 50%;
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgb(19, 19, 19) 20%, rgba(0, 0, 0, 0) 100%);
}
.social-connect {
  border-top: solid 1px #424242;
  padding: 10px 0;
}

.social-connect-btn {
  position: relative;
  top: -12px;
  float: right;
  overflow: hidden;
}

/* gradient in live tv landing page coursel */
.gradientX {
  background: rgb(19, 19, 16);
  background: linear-gradient(
    90deg,
    rgba(19, 19, 16, 1) 0%,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0) 70%,
    rgba(19, 19, 16, 1) 100%
  );
}

/* video player */
.video-js {
  /* position: absolute; */
  /* width: 100% !important; */
  /* height: max-content; */
  /* aspect-ratio: 16/9; */
  display: block;
  margin: auto;
}

/* welcome side bar  */
.open {
  position: relative;
  animation-name: example-open;
  animation-duration: 0.5s;
}

@keyframes example-open {
  0% {
    /* background-color: red; */
    left: -100%;
    top: 0px;
  }
  100% {
    /* background-color: red; */
    left: 0px;
    top: 0px;
  }
}

/* video player */
.vjs-big-play-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.play-btn {
  width: 100px;
  height: 100px;
  background: radial-gradient(
    rgba(255, 0, 128, 0.8) 60%,
    rgba(255, 255, 255, 1) 62%
  );
  border-radius: 50%;
  position: relative;
  display: block;
  margin: 100px auto;
  box-shadow: 0px 0px 25px 3px rgba(255, 0, 128, 0.8);
}

/* triangle */
.play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  transform-origin: center center;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid #fff;
  z-index: 100;
  -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* pulse wave */
.play-btn:before {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate1 2s;
  animation: pulsate1 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.75);
  top: -30%;
  left: -30%;
  background: rgba(198, 16, 0, 0);
}

@-webkit-keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
      0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
      0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

/* new audio player css */
.redProgressBar {
  background: linear-gradient(
    to right,
    red 0%,
    red var(--seek-before-width, 0%),
    white var(--seek-before-width, 0%),
    white 100%
  );
}

/* alert box */

.Toastify__toast {
  background-color: var(--black) !important;
  color: #ffffff !important;
  border-radius: 10px !important;
  font-family: "Poppins" !important;
}

.Toastify__close-button {
  color: var(--gray) !important;
}
.Toastify__progress-bar {
  width: 80% !important;

  margin-left: 5% !important;
  border-radius: 5px !important;
}

/* sub menu styles */
@layer components {
  .submenu-main {
    @apply sm:absolute duration-200 p-2 pt-4 w-[200px] shadow mt-[42px] rounded-md z-50 border-black border-solid border-2 top-[0px] right-0;
  }
  .action-icon-size {
    @apply w-5 h-5;
  }
  .sub-menu-item {
    @apply text-white duration-200 ease-in-out cursor-pointer hover:text-red-600;
  }
}

.backdrop-blur {
  backdrop-filter: blur(10px); /* Adjust blur intensity as needed */
}

/*  */
/* Hide the scrollbar */
/* .scrollbar-hide::-webkit-scrollbar {
  width: 0.25rem;  
  background-color: transparent;
 
}
.scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
} */

/* audio player full screen background */
.bg-dynamic-background {
  background-image: url("../Assets/Images/Music/PlayerFullScreen.svg");
}

/* tooltip css */
.tooltip-container,
.ant-tooltip-inner {
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 99 I !important;
}

/* Style for the tooltip arrow */
.tooltip-arrow {
  border-color: transparent transparent #333 transparent;
}

/* dar mode notification */
.dark-notification {
  background-color: #282c35 !important;
  color: #ffffff !important;
  border: 1px solid #444;

  padding: 10px !important;
  width: 300px !important;
}
.ant-notification-notice-message {
  color: #ffffff !important;
  font-size: 15px !important;
  margin-bottom: 0px !important;
}
.ant-notification-notice-close {
  color: #ffffff !important;
  font-weight: bold !important;
  display: none !important;
}

/* animated text */
.scroll-container {
  /* border: 3px solid black;
  border-radius: 5px; */
  overflow: hidden;
  width: 50%;
  /* Set the fixed width to 50% */
}

.scroll-text {
  /* animation properties */
  display: inline-block;
  /* Display the text in a single line */
  white-space: nowrap;
  /* Prevent text from wrapping to a new line */

  /* Animation styles */
  -moz-transform: translateX(50%);
  -webkit-transform: translateX(50%);
  transform: translateX(50%);

  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}

/* Animation keyframes for Firefox, Chrome, and others */
@keyframes my-animation {
  from {
    -moz-transform: translateX(50%);
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }

  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.scroll-text-full-screen {
  /* Animation properties */
  display: inline-block;
  white-space: nowrap;

  /* Animation styles */
  -moz-transform: translateX(50%);
  -webkit-transform: translateX(50%);
  transform: translateX(50%);

  -moz-animation: my-animation 17s linear infinite;
  -webkit-animation: my-animation 17s linear infinite;
  animation: my-animation 17s linear infinite;
}

@keyframes my-animation1 {
  0% {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  10% {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

/* epg loader colors */
.css-1f0mrh6-Shape,
.css-v5r5zd-Shape {
  background-color: #292727 !important;
}

.css-dit4bh-Shape {
  background-color: var(--red) !important;
}
.css-h8aoeh-Shape,
.css-lz1hyi-Shape {
  background-color: #662323 !important;
}
.css-1ov48cp-Box {
  z-index: 40 !important;
}

/* Dropdown */

.dd-button {
  display: inline-block;
  /* border: 1px solid gray; */
  /* border-radius:20%; */
  padding: 15px 20px 15px 20px;
  background-color: #202020;
  cursor: pointer;
  white-space: nowrap;
}

.dd-button .opt-v {
  padding: 10px 20px !important;
  cursor: pointer;
  white-space: nowrap !important;
}

.dd-button .opt-v:hover {
  background-color: #161616 !important;
}

/* hide scrol bar */
/* Hide the scrollbar for all browsers */
.scrollbar-hide {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer/Edge */
}

/* Optional: Styles for the scrollbar thumb (Chrome, Safari, Edge) */
.scrollbar-hide::-webkit-scrollbar {
  width: 0.25rem;
  /* Set the width to 0 to hide the scrollbar */
  background-color: transparent;
  /* Optional: Change the background color of the scrollbar area */
}

.vjs-poster {
  bottom: 0;
  cursor: pointer;
  display: inline-block;
  height: 0% !important;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  vertical-align: middle;
}

.playlis-title {
  font-family: "Rajdhani", sans-serif !important;
}

.font-secular {
  font-family: "Ubuntu", sans-serif !important;
}

.font-fredoka {
  font-family: "Fredoka", sans-serif !important;
  line-height: 1.1 !important;
}

.font-rubik {
  font-family: "Lexend", sans-serif !important;
}

.font-oswald {
  font-family: "Oswald", sans-serif !important;
}

/* Optional: Styles for the scrollbar thumb (Chrome, Safari, Edge) */
.scrollbar-hide::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Set the color to transparent to hide the thumb */
}

/* custom scroll bar */
/* Custom scrollbar for WebKit browsers */
.custom-scrollbar {
  scrollbar-width: auto;
  /* Hide the default scrollbar */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 0, 0, 0.798);
  /* Customize the thumb color */
  border-radius: 6px;
  /* Rounded thumb */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: rgb(66, 66, 66);
  border-radius: 6px;
  /* Background behind the scrollbar */
}

@media screen and (max-width: 480px) {
  .playerMargin {
    margin-top: -130px;
  }
  .responsive-ppvtrailer {
    width: 100%;
    height: 300px !important;
  }
}

@layer components {
  .social-media-pop-btn {
    @apply px-3 py-2 min-w-[100px] bg-[#F30543] hover:bg-[#c90336] duration-200 ease-in-out rounded-full max-sm:mt-3;
  }
  .social-media-pop-up-btn-section {
    @apply relative bottom-0 flex items-center justify-between w-full h-full my-3 max-sm:block;
  }
}

.ant-input {
  height: 45px;
}

.responsive-ppvtrailer {
  width: 100%;
  height: 500px;
  /* Back to top */
}
.ant-back-top {
  bottom: 50px;
  right: 20px;
}
.ant-back-top-inner {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: black;
  text-align: center;
  font-size: 20px;
}

.ant-back-top-icon {
  color: #f30543;
}

.playnext {
  position: absolute;
  top: 728px;
  right: 100px;
}

@media screen and (max-width: 1700px) {
  .playnext {
    top: 641px;
    right: 172px;
  }
}

@media screen and (max-width: 1700px) {
  .playnext {
    top: 571px;
    right: 139px;
  }
}
@media screen and (max-width: 1440px) {
  .playnext {
    top: 509px;
    right: 139px;
  }
}
@media screen and (max-width: 1366px) {
  .playnext {
    top: 410px;
    right: 125px;
  }
}
@media screen and (max-width: 1155px) {
  .playnext {
    top: 391px;
    right: 125px;
  }
}
@media screen and (max-width: 1000px) {
  .playnext {
    top: 312px;
    right: 125px;
  }
}

@media screen and (max-width: 800px) {
  .playnext {
    top: 228px;
    right: 125px;
  }
}

@media screen and (max-width: 760px) {
  .playnext {
    top: 228px;
    right: 100px;
  }
}

@media screen and (max-width: 755px) {
  .playnext {
    top: 200px;
    right: 20px;
  }
}

@media screen and (max-width: 505px) {
  .playnext {
    top: 140px;
    right: 20px;
  }
}
@media screen and (max-width: 480px) {
  .playnext {
    right: 20px;
    top: 120px;
  }
}
@media screen and (max-width: 440px) {
  .playnext {
    right: 20px;
    top: 100px;
  }
}
@media screen and (max-width: 420px) {
  .playnext {
    right: 20px;
    top: 85px;
  }
}
.css-1uqzzzt {
  position: absolute;
  height: 60px;
  width: 150px;
  top: 0px;
  background: rgb(19, 19, 19);
  z-index: 11 !important;
  left: 0px;
}

.adsbygoogle {
  height: 90px !important;
}

ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}

/* swiper arrow color */

.swiper-button-next {
  color: var(--red);
  height: 100%;
  margin-top: 0;
  top: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically */
  width: 80px;
  margin-right: -40px; /* Adjust as needed */
  background: linear-gradient(to left, #131313 0%, rgba(0, 0, 0, 0) 100%);
}

.swiper-button-prev {
  color: var(--red);
  height: 100%;
  margin-top: 0;
  top: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically */
  width: 80px;
  margin-left: -40px; /* Set the width to 200px */
  background: linear-gradient(to right, #131313 0%, rgba(0, 0, 0, 0) 100%);
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 24px; /* Adjust the font size for the arrow icon */
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0; /* Set your desired opacity for the disabled state */
  cursor: auto;
  pointer-events: none;
}

.css-a39jwx-Inner {
  display: none !important;
}

.e1gp86mg2 {
  display: none !important;
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: transparent;
  border: 1px solid var(--red);
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 20px;
  height: 8px;
  transition: width 0.5s;
  border-radius: 5px;
  background: var(--red);
  border: 1px solid transparent;
}

.blogs-heroimage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.9)),
    url("../Assets/Images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .parallax {
    background-image: url("../Assets/Images/ppv-3.jpeg");
    min-height: 300px;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 80px !important;
    z-index: 40 !important;
  }
}

.typing {
  width: 4em;
  height: 2em;
  padding: 3px;
  margin-left: 0px;
  background: rgb(31 41 55 / var(--tw-bg-opacity));
  border-radius: 20px 20px 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.typing__dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing__dot:nth-child(1) {
  animation-delay: 0s;
}

.typing__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing__dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}
.reactGiphySearchbox-searchForm-input {
  background-color: var(--background);
  border: 1px solid #464646 !important;
  border-radius: 5px !important;
}

/* creator description css */
.creator-description {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.creator-description:hover::after {
  content: attr(data-full-description);
  position: absolute;
  top: 100%;
  left: 30%;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: pre-wrap;
  z-index: 10;
  width: max-content;
  max-width: 100%;
}

.chat-bubble {
  max-width: 100%;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}


