.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none !important;
}
.VIpgJd-ZVi9od-ORHb-OEVmcd {
  visibility: hidden !important;
}
body {
  top: 0% !important;
}
.skiptranslate {
  color: transparent !important;
  content: none !important;
}
.goog-te-combo {
  color: white !important;
  background-color: var(--searchbar) !important;
  border-radius: 10px !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-right: 10px !important;
  width: 170px !important;
}
.goog-te-combo > option {
  color: white !important;
}
.VIpgJd-ZVi9od-ORHb > table > tbody > tr > td {
  display: none !important;
}

select {
  -webkit-appearance: none !important; /* Hides default dropdown icon */
  overflow: hidden !important; /* Hides the scrollbar */
  padding: 5px 10px !important;
}

select::-webkit-scrollbar {
  display: none !important; /* Hides the scrollbar */
}
